import React, { useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

import PAGES from '../../constants/pages';

const isBrowser = typeof window !== 'undefined';

const addLead = (data) => {
  const addLeadPostUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:5001/jonaspiela-com/europe-west3/addLead'
      : 'https://europe-west3-jonaspiela-com.cloudfunctions.net/addLead';

  return axios.post(addLeadPostUrl, data);
};

export default function LeadgenForm(props) {
  const [values, setValues] = useState({
    title: props.title,
    name: '',
    company: '',
    email: '',
    formSubmitted: false,
    loading: false,
    success: false,
  });
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    addLead(values).then(onSubmitSuccess).catch(onSubmitError);
  };

  const trackDownloadSuccess = () => {
    if (typeof window !== `undefined` && window.plausible) {
      window.plausible('Download', { props: { title: props.title } });
    }
  };

  const trackDownloadError = (e) => {
    if (typeof window !== `undefined` && window.plausible) {
      window.plausible('Download Error', { props: { title: props.title, e } });
    }
  };

  const openDownloadUrl = () => {
    window.open(props.downloadUrl);
  };

  const onSubmitSuccess = ({ data: { success } }) => {
    trackDownloadSuccess();

    setValues({
      ...values,
      formSubmitted: true,
      success,
    });

    openDownloadUrl();
    saveAccess();
  };

  const onSubmitError = (error) => {
    trackDownloadError(error);
    setValues({
      ...values,
      formSubmitted: true,
      success: false,
    });
  };

  const submitFailed = () => {
    return values['formSubmitted'] && !values['success'];
  };

  const hasAccess = () => {
    return (
      isBrowser &&
      (localStorage.getItem(props.slug) === 'true' ||
        (values['formSubmitted'] && values['success']))
    );
  };

  const saveAccess = () => {
    isBrowser && localStorage.setItem(props.slug, 'true');
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.name && fieldValues.company && fieldValues.email;

    return isValid;
  };

  return (
    <>
      <Box
        sx={{
          padding: 3,
          backgroundColor: 'background.paper',
        }}
      >
        <Typography variant="h5">{props.callToAction}</Typography>
        {!hasAccess() && !submitFailed() && (
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                label="Vor- und Nachname"
                name="name"
                variant="standard"
                onChange={handleInputValue}
                fullWidth
                required
              />
              <TextField
                label="Unternehmen"
                name="company"
                variant="standard"
                onChange={handleInputValue}
                fullWidth
                required
              />
              <TextField
                label="E-Mail-Adresse"
                name="email"
                variant="standard"
                onChange={handleInputValue}
                fullWidth
                required
              />
              <Typography variant="body2">
                Wir hoffen, dass die Unterlagen hilfreich sind. Unter Umständen
                würden wir dich persönlich kontaktieren, um zu erörtern, ob wir
                darüber hinaus hilfreich sein können. Siehe auch{' '}
                <Link to={PAGES.PRIVACY.to}>Datenschutz</Link>.
              </Typography>
              <LoadingButton
                type="submit"
                variant="text"
                loading={loading}
                disabled={!formIsValid()}
              >
                {props.callToAction}
              </LoadingButton>
            </Stack>
          </form>
        )}

        {hasAccess() && (
          <>
            <Typography>
              Vielen Dank, dass du dich für unsere Arbeit interessierst!
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Lade nun dein Dokument hier herunter:
            </Typography>
            <Button
              variant="text"
              sx={{ width: '100%' }}
              onClick={() => {
                openDownloadUrl();
              }}
            >
              {props.callToAction}
            </Button>
          </>
        )}

        {submitFailed() && (
          <Typography>Es gab leider einen Fehler :(</Typography>
        )}
      </Box>
    </>
  );
}
